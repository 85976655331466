$columns: 12;
$container-base-width: 1200px;
$container-offset: $base-spacing-unit;

$desktop: 992px;
$tablet: 768px;
$mobile: 576px;

$mq: (
  $mobile: $columns,
  $tablet: $columns,
  $desktop: $columns,
);

.container {
  max-width: $container-base-width;
  padding-left: $container-offset;
  padding-right: $container-offset;
  margin: 0 auto;
  @media only screen and (max-width: $mobile) {
    padding-left: ($container-offset * 2);
    padding-right: ($container-offset * 2);
  }
}

.container-full {
  max-width: 100%;
  padding-left: $container-offset;
  padding-right: $container-offset;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  box-sizing: border-box;
  // margin-left: ($base-spacing-unit * -1);
  // margin-right: ($base-spacing-unit * -1);
}

.col {
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;
}

[class^="col-"] {
  flex: auto;
}

@for $i from 0 through $columns {
  .col-#{$i} {
    width: percentage($i / $columns);
  }
}

@for $i from 0 through $columns {
  .push-#{$i} {
    margin-left: percentage($i / $columns);
  }
}

@for $i from 0 through $columns {
  .pull-#{$i} {
    margin-right: percentage($i / $columns);
  }
}

@each $key, $val in $mq {
  // DESKTOP
  @media (max-width: $desktop) {
    @for $i from 0 through $columns {
      .col-d-#{$i} {
        width: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .push-d-#{$i} {
        margin-left: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .pull-d-#{$i} {
        margin-right: percentage($i / $columns);
      }
    }
  }

  // TABLET
  @media (max-width: $tablet) {
    @for $i from 0 through $columns {
      .col-t-#{$i} {
        width: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .push-t-#{$i} {
        margin-left: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .pull-t-#{$i} {
        margin-right: percentage($i / $columns);
      }
    }
  }

  // MOBILE
  @media (max-width: $mobile) {
    @for $i from 0 through $columns {
      .col-m-#{$i} {
        width: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .push-m-#{$i} {
        margin-left: percentage($i / $columns);
      }
    }

    @for $i from 0 through $columns {
      .pull-m-#{$i} {
        margin-right: percentage($i / $columns);
      }
    }
  }
}
