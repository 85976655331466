/* Buttons */
.button {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  font: inherit;
  border: none;
  outline: none;
  -webkit-appearance: none;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  letter-spacing: 0.5px;
  color: $brand-color;
  transition: all 0.35s ease;
  font-size: 13px;
  font-weight: 500;
  color: $dark;
  background-color: #ffffff;
  border-color: $brand-color;
  border-style: solid;
  border-width: 2.5px;
  height: 40px;
  padding: 0px 1.8rem;

  cursor: pointer;
  &:hover {
    border-color: $brand-color;
    background-color: $brand-color;
    color: $dark;
  }

  &--primary {
    padding: 0px 1.8rem;
    height: 50px;
    font-size: 13px;
    font-weight: 500;
    background-color: $dark;
    &:hover {
      background-color: darken($brand-color, 5%);
    }
  }

  &--small {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 13px;
    font-weight: 500;
  }

  &--middle {
    position: relative;
    height: 60px;
    width: 100%;
    max-width: 280px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: $dark;

    &:hover {
      background-color: darken($dark, 1%);
    }
  }

  &--big {
    display: block;
    width: 100%;
  }
}
