/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  transition: $global-transition;
  // border-bottom: 1px solid #efefef;
  // box-shadow: 0 2px 9px -1px rgba(0, 0, 0, 0.04);
  background-color: "#00000000";
  &.is-hide {
    top: -80px;
  }
}

.header__inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
}

/* Logo */
.logo__link {
  font-family: "Lora", sans-serif;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  &:hover {
    color: $dark;
  }
}

.logo__image {
  max-height: 50px;
}

/* Nav */
.main-nav {
  margin-left: auto;

  @media only screen and (max-width: $desktop) {
    display: none;
  }
}

.main-nav__box {
  display: flex;
  align-items: center;

  .nav__icon-close {
    display: none;
    font-size: 24px;
    text-align: right;

    .ion-md-close {
      cursor: pointer;
    }
  }

  .nav__title {
    display: none;
  }

  @media only screen and (max-width: $desktop) {
    display: block;

    .nav__icon-close {
      display: block;
    }

    .nav__title {
      display: block;
      margin: 0 10px 10px;
      padding-bottom: 10px;
      color: $gray;
      border-bottom: 1px solid $light-gray;
    }
  }
}

// a {
//   color: $link-color;
//   text-decoration: none;
//   letter-spacing: 0.15em;

//   display: inline-block;
//   padding: 15px 20px;
//   position: relative;
// }

// a:after {
//   background: none repeat scroll 0 0 transparent;
//   bottom: 0;
//   content: "";
//   display: block;
//   height: 2px;
//   left: 50%;
//   position: absolute;
//   background: $link-color;
//   transition: width 0.3s ease 0s, left 0.3s ease 0s;
//   width: 0;
// }
// a:hover:after {
//   width: 100%;
//   left: 0;
// }

.nav__list {
  .nav__item {
    display: inline-block;
    margin: 0 15px;

    .nav__link {
      position: relative;
      padding: 5px 7px;
      font-size: 13px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 0.5px;
      border-radius: 3px;
      text-transform: uppercase;
      &:hover {
        // color: $dark;
        // background-color: $brand-color;
      }
    }

    .nav__link:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: $link-color;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    .nav__link:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

.nav-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 21px;

  .nav__icon {
    cursor: pointer;
    &:first-child {
      margin-right: 15px;
    }
  }

  .nav__icon-menu {
    display: none;
  }

  @media only screen and (max-width: $desktop) {
    margin-left: auto;

    .nav__icon-menu {
      display: block;
    }
  }
}

// Mobile Nav
.mobile-nav {
  .nav__list {
    .nav__item {
      display: block;
      margin-bottom: 30px;
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }

      .nav__link {
        position: relative;
        font-size: 16px;
        color: $text-color;
      }

      @media only screen and (max-width: $mobile) {
        margin-bottom: 20px;
      }
    }
  }
}

.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  background-color: $white;
  transition: all 0.5s ease-in-out;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    background-color: rgba($white, 0.9);
  }
  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
  @media only screen and (min-width: $desktop) {
    display: none;
  }
}

.top-nav__box {
  display: flex;
  align-items: center;
  width: 80%;
  padding-top: 200px;
  margin: 0 auto;
  .nav__icon-close {
    position: absolute;
    top: 15px;
    right: 40px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: $dark;
    transition: all 0.25s ease;
    cursor: pointer;
  }
  @media only screen and (max-width: $tablet) {
    padding-top: 100px;
  }
}

.nav-grid {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
}

.nav-grid__item {
  margin-bottom: 30px;
  @media only screen and (max-width: $tablet) {
    height: auto;
  }
}

.nav-grid__title {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: $text-color;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    display: block;
    width: 25px;
    height: 2px;
    background-color: $gray-blue;
  }
  @media only screen and (max-width: $tablet) {
    margin-bottom: 30px;
  }
}
