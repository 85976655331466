@import "variables";
@import "grid";
@import "normalize";
@import "header";
@import "buttons";
@import "card";
@import "helpers";
@import "contact";
@import "animate";
@import "home-page";
@import "footer";
@import "floating-cart";
@import "post";

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $text-color;
  background-color: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input,
  textarea {
    border: 1px solid $border-color;
    outline: none;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 26px;
  }
}

::placeholder {
  color: #666;
}

*::selection {
  color: $dark;
  background-color: rgba($brand-color, 0.4);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

blockquote {
  position: relative;
  padding: 20px 20px 20px 50px;
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  color: $text-color;

  &:before {
    content: "\201C";
    position: absolute;
    font-family: $heading-font-family;
    top: 0.3em;
    left: -0.01em;
    font-size: 4em;
    color: $brand-color;
  }

  cite {
    font-size: 16px;
    font-style: normal;
    color: $text-color;
    &:before {
      content: "\2014""\00a0";
    }
  }

  @media only screen and (max-width: $mobile) {
    padding: 20px 20px 20px 35px;
    font-size: 18px;
    line-height: 28px;
  }
}

pre {
  overflow: auto;
  padding: 15px;
  margin-bottom: 0;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

img,
.zoom-img-wrap {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  & + em {
    display: block;
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    color: $gray-blue;
  }
}

a {
  text-decoration: none;
  color: $link-color;
  transition: $global-transition;
  &:hover {
    color: darken($link-color, 15%);
  }
}

hr {
  display: block;
  height: 1px;
  margin: 50px 0;
  border: 0;
  background: $light-gray;
}

.table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
}

table {
  font-size: 12px;
  color: $dark;
  width: 100%;
  border-width: 1px;
  border-color: $dark;
  border-collapse: collapse;
}

table th {
  padding: 10px;
  font-size: 16px;
  text-align: left;
  border: 1px solid $dark;
  color: $white;
  font-weight: 500;
  background-color: $dark;
}

table tr {
  background-color: $light-gray;
  transition: all 0.3s ease;
  &:nth-child(even) {
    background-color: transparent;
  }
}

table td {
  padding: 10px;
  font-size: 14px;
  border: 1px solid $dark;
}

table tr:hover {
  background-color: $brand-color;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background: #ffffff;
  transition: visibility 0.3s, opacity 0.3s ease;
}
.loader .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.loader .cube1,
.loader .cube2 {
  background-color: #2d3338;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}
.loader .cube1 {
  -webkit-animation: sk-cubemove 1800ms infinite ease-in-out;
  animation: sk-cubemove 1800ms infinite ease-in-out;
}
.loader .cube2 {
  -webkit-animation: sk-cubemove-2 1800ms infinite ease-in-out;
  animation: sk-cubemove-2 1800ms infinite ease-in-out;
  transform: translateX(42px) translateY(42px) rotate(-179deg);
  -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
}
@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-webkit-keyframes sk-cubemove-2 {
  25% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  50% {
    -webkit-transform: rotate(-360deg);
  }
  75% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  100% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
}
@keyframes sk-cubemove-2 {
  25% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  50% {
    transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
  }
  50.1% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
  75% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  100% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
}
