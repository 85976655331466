.homepage {
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
}

.module-1 {
  @media only screen and (max-width: $mobile) {
    transform: translate(0%, -7.5%);
  }
}

.module-1 h1 {
  font-size: 3em;

  @media only screen and (max-width: 1110px) {
    font-size: 2.25em;
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 0.3rem;
    font-size: 1.5em;
  }
}

.module-1 p {
  font-size: 2.25em;

  @media only screen and (max-width: 1110px) {
    font-size: 1.75em;
  }

  @media only screen and (max-width: $mobile) {
    margin-top: 0.3rem;
    font-size: 1.25em;
  }
}

.module-1 button {
  font-family: Lora;
  font-weight: 700;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: white;
  font-size: 2.5em;
  position: relative;
  margin: 1em;

  @media only screen and (max-width: 1110px) {
    font-size: 1.75em;
  }

  @media only screen and (max-width: $mobile) {
    white-space: nowrap;
    font-size: 1em;
  }
}

.module-1 img {
  position: absolute;
  max-width: unset;
}

.img-1-left {
  left: 0;
  bottom: 0;
  transform: translate(-100%, 0%) translateX(-8em);
  height: 350px;

  @media only screen and (max-width: 1110px) {
    transform: translate(-100%, 0%) translateX(-1em);
    height: 300px;
  }

  @media only screen and (max-width: $mobile) {
    transform: translate(-100%, 80%) translateX(-2.75em);
    height: 240px !important;
    display: none;
  }
}

.img-1-left-mobile {
  left: 0;
  bottom: 0;
  transform: translate(0%, 0%);
  height: 400px;
  display: none;

  @media only screen and (max-width: $mobile) {
    transform: translate(-100%, 100%) translateY(-3em) translateX(-1.5em);
    height: 240px !important;
    display: unset;
  }
}

.img-1-right {
  right: 0;
  bottom: 0;
  transform: translate(100%, 0%) translateX(+3em);
  height: 250px;

  @media only screen and (max-width: 1110px) {
    transform: translate(100%, 0%) translateX(+1em);
    height: 150px;
  }

  @media only screen and (max-width: $mobile) {
    transform: translate(100%, 100%) translateX(-4em) translateY(+1em);
    height: 150px;
  }
}

.screen-two-left-img {
  // right: 0;
  // bottom: 0;
  // transform: translate(100%, 0%) translateX(+3em);
  // height: 250px;

  // @media only screen and (max-width: 1110px) {
  //   transform: translate(100%, 0%) translateX(+1em);
  //   height: 150px;
  // }

  // @media only screen and (max-width: $mobile) {
  //   transform: translate(100%, 100%) translateX(-4em) translateY(+1em);
  //   height: 150px;
  // }
}

.screen-two-right-img {
  // right: 0;
  // bottom: 0;
  // transform: translate(100%, 0%) translateX(+3em);
  // height: 250px;

  // @media only screen and (max-width: 1110px) {
  //   transform: translate(100%, 0%) translateX(+1em);
  //   height: 150px;
  // }

  // @media only screen and (max-width: $mobile) {
  //   transform: translate(100%, 100%) translateX(-4em) translateY(+1em);
  //   height: 150px;
  // }
}

.module-3 h1 {
  font-size: 2em;
  margin-bottom: 0;

  @media only screen and (max-width: 1000px) {
    font-size: 1.75em;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 1.25em;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 1.25em;
  }
}

.module-3 img {
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // transform: translate(0%, 100%) translateY(2rem);
  max-height: 260px;

  @media only screen and (max-width: 1000px) {
    max-height: 200px;
  }

  @media only screen and (max-width: $tablet) {
    max-height: 150px;
  }

  @media only screen and (max-width: $mobile) {
    max-height: 150px;
    // transform: translate(0%, 100%) translateY(3rem);
  }
}

.module-2 h1 {
  font-size: 3em;

  @media only screen and (max-width: 1000px) {
    font-size: 1.75em;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 1.25em;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 2.5em;
  }
}

.tasty-dips {
  font-size: 3.75em !important;

  @media only screen and (max-width: $mobile) {
    font-size: 3em !important;
  }
}

.module-2 img {
  // position: absolute;
  // bottom: 0;
  // right: 0;
  max-height: 260px;
  padding-left: 1rem;
  padding-right: 1rem;

  @media only screen and (max-width: 1000px) {
    max-height: 200px;
  }

  @media only screen and (max-width: $tablet) {
    max-height: 150px;
  }

  @media only screen and (max-width: $mobile) {
    max-height: 150px;
    // transform: translate(0%, 100%) translateY(3rem);
  }
}

.module {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  max-width: 100vw;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.module__item {
  align-items: center;
  display: flex;
  height: 20%;
  justify-content: center;
  outline: black solid;
}

.module__item:nth-child(odd) {
  background-color: #fff;
  color: #f73859;
}

.module__item:nth-child(even) {
  background-color: #f73859;
  color: #f1d08a;
}

.underline {
  -webkit-text-decoration: #ffb822 underline;
  -webkit-text-decoration-thickness: 0.05em;
  -webkit-text-underline-offset: 0.2em;
}

/* Article */
.article {
  &:hover {
    .article__title a {
      color: $dark;
      box-shadow: 0 -1px 0 0 $white inset, 0 -3px 0 0 $brand-color inset;
    }
  }
}

.article__image {
  position: relative;
  display: inline-block;
  &:hover {
    &:after {
      transition: 0.4s ease-in-out;
      opacity: 1;
      visibility: visible;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}

.article__content {
  padding: 20px 0;
  background: white;
}

.article__title {
  margin-bottom: 10px;
  line-height: 32px;
  transition: all 0.3s ease-in;
  @media only screen and (max-width: $mobile) {
    line-height: 30px;
  }
}

.article__excerpt {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
}

.article__meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.article-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.article-tags__box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  .article__tag {
    height: 23px;
    padding: 0 7px;
    margin: 0 5px 10px 0;
    font-size: 10px;
    line-height: 23px;
    letter-spacing: 1px;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: $brand-color;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $dark;
      background-color: darken($brand-color, 10%);
    }

    @media only screen and (max-width: $mobile) {
      height: 20px;
      line-height: 20px;
    }
  }
}

.article__date {
  font-size: 13px;
  color: $text-color;
  &::before {
    content: "";
    position: relative;
    bottom: 0.2rem;
    display: inline-block;
    height: 3px;
    width: 3px;
    margin: 0 0.5rem 0 0.5rem;
    border-radius: 50%;
    background-color: rgba($text-color, 0.8);
  }

  // @media only screen and (max-width: $mobile) {
  //   display: none;
  // }
}

.article__author {
  display: flex;
  align-items: center;
  &-image {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $dark;
  }
  &-link {
    font-size: 14px;
    color: $dark;
    &:hover {
      color: $dark;
    }
  }
}

// .container__inner {
//   &.without-author {
//     margin-top: 150px;
//     @media only screen and (max-width: $mobile) {
//       margin-top: 120px;
//     }
//   }
// }
