div.floating-cart {
	position:absolute;
	top:0;
	left:0; 
	width:259px;	
	height:252px;
	background:#fff;
	z-index:200;
	overflow:hidden;
	box-shadow:0px 5px 31px -1px rgba(0, 0, 0, 0.15);
	display:none;
}

div.floating-cart .stats-container{display:none;}
div.floating-cart .product-front{width:100%; top:0; left:0;}
div.floating-cart.moveToCart{
	right: 75px !important;
	top: 55px !important;
	width: 47px;
	height: 47px;	
	-webkit-transition: all 800ms ease-in-out;
	   -moz-transition: all 800ms ease-in-out;
		-ms-transition: all 800ms ease-in-out;
		 -o-transition: all 800ms ease-in-out;
 		    transition: all 800ms ease-in-out; 
}
body.MakeFloatingCart div.floating-cart.moveToCart{	
	right: 90px !important;
	top: 100px !important;
	width: 21px;
	height: 22px;
	box-shadow:0px 5px 31px -1px rgba(0, 0, 0, 0);
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		-ms-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
 		    transition: all 200ms ease-out;
}