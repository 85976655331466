/* Animate */
.fadein {
  opacity: 0;
  @media only screen and (max-width: $mobile) {
    opacity: 1;
  }
}

.inview {
  opacity: 1;
  animation: fade-in-bottom 0.5s 1 cubic-bezier(0.3, 0.1, 0.3, 1);
}

@keyframes fade-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
