/* Footer */
.footer {
  position: relative;
  margin-top: 40px;
  background-color: $dark;
  .footer__inner {
    padding: 50px 0 40px;
  }
}

/* Copyright */
.copyright {
  margin-top: 20px;
  font-size: 13px;
  text-align: center;
  color: $gray;
  a {
    color: $white;
    &:hover {
      color: $brand-color;
    }
  }
}
